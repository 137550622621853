html, body, #root {
  height: 100%;
  overflow: hidden;
  min-width:400px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.selectable{
  -webkit-touch-callout: default;
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Microsoft Sans Serif", sans-serif;
  font-size: 9pt;
  cursor: default;
}

@font-face {
  font-family: "Microsoft Sans Serif";
  src: url("Microsoft Sans Serif.ttf")
}

@font-face {
  font-family: "Dos";
  src: url("Perfect.DOS.VGA.437.ttf")
}

@media screen and (max-width: 800px) {
  .container {
    width:50px;
  }
}

#taskbar-items{
  display:flex;
  flex-direction:row;
}

.taskbar-item{
  padding: 4px;
  align-items:center;
  width:100%;
  max-width:150px;
  min-width: 0;
  overflow: hidden;
  margin-right: 3px;
  word-break: break-all;
}

.hidden {
  display: none !important;
}

.fill {
  flex: 1;
}

.front {
  /* z-index: 99999 !important; */
}

.center-items {
  display: flex;
  align-items: center;
  justify-content: center;
}

.vertical-center-items {
  display: flex;
  align-items: center;
}

.border-only {
  border-top: 2px solid white;
  border-left: 2px solid white;
  border-bottom: 2px solid black;
  border-right: 2px solid black;
}

.non-interactable {
  border-top: 2px solid #888886;
  border-left: 2px solid #888886;
  border-bottom: 2px solid white;
  border-right: 2px solid white;
}

.interactable {
  border-top: 2px solid white;
  border-left: 2px solid white;
  border-bottom: 2px solid black;
  border-right: 2px solid black;
  cursor: pointer;
}

.interactable:active {
  border-top: 2px solid black;
  border-left: 2px solid black;
  border-bottom: 2px solid white;
  border-right: 2px solid white;
}

.interactable-active{
  border-top: 2px solid black;
  border-left: 2px solid black;
  border-bottom: 2px solid white;
  border-right: 2px solid white;
}

.margin {
  margin: 2px;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.desktop {
  position:relative;
  display: flex;
  background-color: #008080;
  justify-content: center;
  align-items: center;
}

.taskbar-wrapper {
  display: flex;
  flex-direction: row;
  background-color: #c0c0c0;
  height: 25px;
  padding: 3px;
  z-index: 90;
}

.taskbar-menu-holder {
  display: flex;
  padding: 1px;
  margin-right:5px;
}

.taskbar-menu {
  font-weight: bold;
  text-align: center;
  padding: 2px;
  border: dotted 1px #c0c0c0;
}

.taskbar-menu:active {
  border: dotted 1px black;
}

.taskbar-icons {
  padding: 5px;
  cursor: default;
  font-size: .9em;
}

.taskbar-icons :not(:last-child) {
  margin-right: 2px;
}

.menu {
  position: absolute;
  left: 0;
  display: flex;
  width: 250px;
  background-color: #c0c0c0;
  align-self: flex-end;
  padding: 2px;
  z-index: 99999;
}

.menu-sidebar {
  display: flex;
  flex-direction: column;
  width: 25px;
}

.menu-sidebar-text {
  background-image: radial-gradient(circle, #1600f4, #070182);
  color: #ffffff;
  writing-mode: vertical-rl;
  transform: rotate(-180deg);
  font-size: 1.25em;
  padding-top: 5px;
  letter-spacing: 1px;
}

.menu-sidebar-rest {
  background-color: #070182;
}

.menu-items {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 2px;
}

.menu-item {
  display: flex;
  align-items: center;
}

.menu-item .img {
  margin-right: 3px;
}

.menu-item .center-items::first-letter {
  text-decoration: underline;
}

.menu-item:hover {
  background-color: #060180;
  color: #ffffff;
  cursor: pointer;
}

.chevron {
  margin-left: auto;
  border-left: 6px solid black !important;
  border: 6px solid #c0c0c0;
}

.chevron-up {
  margin-left: auto;
  border-bottom: 4px solid #7f7f7f !important;
  border: 4px solid #c0c0c0;
  margin-top: -2px;
}

.chevron-down {
  margin-left: auto;
  border-top: 4px solid #7f7f7f !important;
  border: 4px solid #c0c0c0;
  margin-top: 2px;
}

.chevron-left {
  border-right: 4px solid #7f7f7f !important;
  border: 4px solid #c0c0c0;
  position: relative;
  left: -2px;
}

.chevron-right {
  border-left: 4px solid #7f7f7f !important;
  border: 4px solid #c0c0c0;
  position: relative;
  right: -2px;
}

.menu-item:hover .chevron {
  border-left: 6px solid #ffffff !important;
  border: 6px solid #060180;
  cursor: pointer;
}

.fullscreen{
  position: fixed;
  top: 0;
  left:0;
  width: 100%;
  height: 100%;
  z-index: 100 !important;
}

.shutdown{
  background-image: url("./images/shutdown_2x.png");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #000000;
  z-index: 9999999;
}

.off{
  background-color: #000000;
  z-index: 99999999;
}

.bsod-wrapper {
  font-family: 'Dos', monospace;
  color: #ffffff;
  font-size: 1.5em;
  background-color: #0d00aa;
  line-height: 1.25em;
}

.bsod {
  width: 75%;
  max-width: 1024px;
}

.bsod p:first-of-type {
  text-align: center;
}

.bsod p:last-of-type {
  margin-top: 2em;
  text-align: center;
}

.bsod-headline {
  color: #0d00aa;
  background-color: #aaaaaa;
  padding: 0 10px;
  font-size: 1.3em;
  line-height: 1.5em;
}

.bsod li {
  position: relative;
  list-style: none;
}

.bsod li:before {
  content: "*";
  position: absolute;
  left: -15px;
}

.divider {
  border-top: 2px solid #a2a2a2;
  border-bottom: 2px solid #d2d3d3;
}

.desktop-icons{
  position:absolute;
  top:0;
  left:0;
  z-index: 0;
}

a {
  color: purple
}

.desktop-icons>div{
  display: inline-block;
  text-align: center;
  margin: .5em;
  cursor: pointer;
}

.desktop-icons>div>a{
  color:inherit;
  text-decoration: none;
}

.desktop-icons img {
  margin: .5em;
}

.caption {
  display: block;
}

.welcome-textpad-window {
  max-width: 600px;
  padding: 1px;
  padding-bottom: 3px;
  max-height: 500px;
}

.welcome-textpad-window .window-content {
  margin: 1px;
}

.system-properties-window {
  max-width: 400px;
  height: fit-content;
}

.console-window {
  width:100%;
  height: 300px;
  width: 600px;
}

.console-content {
  background-color: #000000;
  color: #ffffff;
  padding: 5px;
  font-family: 'Dos', monospace;
  font-size: 1.25em;
  overflow-y: scroll;
}

.text-area-container {
  display: flex;
}

.text-area-border {
  display:flex;
  flex-direction:column;
  min-height:0;
  border-left: 1px solid black;
  border-top: 1px solid black;
  flex:auto;
}

.text-area {
  padding: 5px;
  font-family: 'Dos', monospace;
  font-size: 1.25em;
  background: #ffffff;
  overflow-y: scroll;
  flex: auto;
}

.text-area p {
  cursor: text;
}

.header {
  color: #bdbdbd;
  background-image: linear-gradient(to right, #7e7e7e, #afafaf);
  cursor: pointer;
}

.header.active {
  color: #ffffff;
  background-image: linear-gradient(to right, #060180, #0f84d0);
}

.scrollbar-v {
  display: flex;
  flex-direction: column;
  width: 16px;
  background-color: #c0c0c0;
  background-image: linear-gradient(45deg, #d5d5d5 25%, transparent 25%), linear-gradient(-45deg, #d5d5d5 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #d5d5d5 75%), linear-gradient(-45deg, transparent 75%, #d5d5d5 75%);
  background-size: 2px 2px;
  background-position: 0 0, 0 1px, 1px -1px, -1px 0;
}

.scrollbar-h {
  display: flex;
  height: 16px;
  background-color: #c0c0c0;
  background-image: linear-gradient(45deg, #d5d5d5 25%, transparent 25%), linear-gradient(-45deg, #d5d5d5 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #d5d5d5 75%), linear-gradient(-45deg, transparent 75%, #d5d5d5 75%);
  background-size: 2px 2px;
  background-position: 0 0, 0 1px, 1px -1px, -1px 0;
}

.scroll-button {
  width: 8px;
  height: 8px;
  padding: 2px;
  background-color: #c0c0c0;
}

.window {
  display: flex;
  flex-direction: column;
  background-color: #c0c0c0;
  box-sizing:border-box;
}

.header {
  font-weight: bold;
  padding: 2px 3px;
  height: 20px;
}

.header>div:not(:first-child) {
  margin: 0 1px;
  height: 16px;
  width: 16px;
  background-color: #c0c0c0;
}

.header div img {
  width: 10px;
  height: 10px;
}

.header-icon {
  margin-right: 2px;
  width: 16px !important;
  height: 16px !important;
  vertical-align:middle;
}

.window-tabs {
  display: flex;
  margin: 5px 5px 0 5px;
}

.window-tabs>div {
  padding: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-top: 2px solid white;
  border-left: 2px solid white;
  border-right: 2px solid black;
}

.window-tabs-active {
  z-index: 1;
  background-color: #c0c0c0;
  height: 80%;
  margin-top: -1px;
}

.window-menu-items {
  display: flex;
  flex-direction: row;
  margin: 3px;
}

.window-menu-items>div {
  margin-right: 10px;
}

.window-content {
  margin: 0 5px 5px 5px;
  display: flex;
  flex-direction: row;
}

.window-action-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 2px 5px 7px 5px;
}

.window-action-buttons>div {
  width: 70px;
  height: 20px;
  padding: 2px;
}

.window-action-buttons>div:not(:last-child) {
  margin-right: 4px;
}

.system-properties {
  margin-top: 20px;
  line-height: 1.5em;
  padding: 8px;
}

.system-properties ul {
  padding-inline-start: 25px;
  margin-block-start: 0;
  margin-block-end: 1.5em;
}

.system-properties li {
  list-style: none;
}

.system-properties-image {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  margin-left: 50px;
  margin-right: 20px;
}

.system-properties-image img {
  width: 128px;
  height: 128px;
}

.dropdown-holder {
  display: flex;
  flex-direction: row;
}

.dropdown {
  height: 16px;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  width: 80px;
  text-align: center;
}

.dropdown .chevron-down {
  border-top: 4px solid #000000 !important;
}

.dropdown-internal {
  overflow: hidden;
}

.icon-holder {
  display: flex;
  flex-direction: row;
}

.icon-button {
  padding: 1px;
}

#content-wrapper{
  display: flex;
  flex-direction: row;
}

.maximized{
  max-height: none;
  max-width: none;
  /*position: absolute !important;*/
  top: 0 !important;
  left: 0 !important;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.lastLineOfBSOD:after{
  content: "_";
  animation: blink-animation 1.5s steps(5,start) infinite;
  -webkit-animation: blink-animation 1.5s steps(5,start) infinite;
}

.multi-window-wrapper{
  display:flex;
  flex-direction:column;
}

.multi-window-wrapper .window{
  position:relative;
  min-height:0;
  flex: 1 1;
  max-width: 100%;
}

@keyframes blink-animation {
    to {
        visibility: hidden;
    }
}

@-webkit-keyframes blink-animation {
    to {
        visibility: hidden;
    }
}
